
import axios from "axios";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer/Footer";

import { ModalProgress, ModalError, ConstProgress } from "../components/ModalComponents";

// Images 
import BackTopBtn from "../assets/images/up-arrow.png";
import { useState } from "react";

const Header = () => {

    return (

        <>
            {/* Header */}
            <header className="ex-header bg-dark-blue mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <h1>Contact Us</h1>
                        </div>
                    </div>
                </div>
            </header>
            {/* end of header */}
        </>

    );

}

const ContactResult = (props) => {

    const { response } = props;

    return (
        <>
            <div className="row">
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                />
                <div className="col-sm-12 col-lg-9 mt-sm-0 mt-lg-0 d-flex justify-content-center align-items-center">
                    <div>
                        <h3>Email Sent !</h3>
                        <hr />
                        <div className="mt-5 mb-5">
                            <span style={{ color : "#00c9db", fontWeight: 700, fontSize: '1.25rem', lineHeight: '2.5rem', fontFamily: " 'Montserrat', 'sans-serif' " }} >
                                {response}
                            </span>
                        </div>
                        <button
                            type="button"
                            className="btn-outline-reg"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    );

}

const Form = () => {

    const [ ModalComponent, setModalComponent ] = useState(<ModalProgress title={"Sending..."} />);

    const [ sender, setSender ] = useState(null);
    const [ email, setEmail ] = useState(null);
    const [ message, setMessage ] = useState(null);

    const onFormSub = (e) => {
        
        e.preventDefault();

        const mail = {
            "sender": sender,
            "email": email,
            "message": message,
        }

        axios({
            method: 'post',
            url: 'https://api.zedkira.com/send/mail',
            data: mail,
        })
        .then(response => {

            if(response.data.success){

                setModalComponent(<ConstProgress percent={100} status={"success"} title={"Email Sent!"} />);

                setTimeout(() => {
                    setModalComponent(<ContactResult response={response.data.message} />);
                }, 500);
                    
            } else {
                setModalComponent(<ModalError error={response.data.error} />);
            }

        })
        .catch(error => {
            setModalComponent(<ModalError error={error.message} />);
        });

    }

    return (
        <>
            <div className="ex-form-1 pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <p className="mb-5">
                                Your feedback will be appreciated. Please fill out the below form
                                or send us an email to,   
                                <a href="mailto:admin@zedkira.com">  deepface@zedkira.com</a>.
                            </p>

                            <form>

                                <div className="mb-4 form-floating">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput4"
                                        placeholder="Name"
                                        onChange={(e) => setSender(e.target.value)}
                                    />
                                    <label htmlFor="floatingInput4">Name</label>
                                </div>

                                <div className="mb-4 form-floating">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="floatingInput5"
                                        placeholder="name@example.com"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <label htmlFor="floatingInput5">Email</label>
                                </div>

                                <div className="mb-4 form-floating">
                                    <textarea
                                        className="form-control"
                                        id="floatingTextarea1"
                                        placeholder="Your message here"
                                        rows={6}
                                        defaultValue={""}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                    <label htmlFor="floatingTextarea1">Message</label>
                                </div>

                                {/* <div className="mb-4 form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="exampleCheck2"
                                    />
                                    <label className="form-check-label" htmlFor="exampleCheck2">
                                        Quis varius quam quisque id diam enim facilisis{" "}
                                        <a href="privacy.html">Privacy Policy</a> sine{" "}
                                        <a href="terms.html">Terms &amp; Conditions</a>
                                    </label>
                                </div> */}

                                <button 
                                    type="submit" data-bs-toggle="modal" data-bs-target="#contactModal" 
                                    onClick={(e) => onFormSub(e)} className="form-control-submit-button"
                                >
                                    Submit
                                </button>

                            </form>

                        </div>
                    </div>
                </div>
            </div>

            <div className="details-modal">
                <div
                    id="contactModal"
                    className="modal fade"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {ModalComponent}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default function Contact(props) {

    const { scrolltop, scrollTOP } = props;

    return (
        <>
            <Navbar scrolltop={scrolltop} />
            
            <Header />

            <Form />

            <button onClick={scrollTOP} style={{ display: scrolltop > 20 ? 'block' : 'none'  }} id="myBtn">
                <img src={BackTopBtn} alt="Scroll Top" />
            </button>

            <Footer />
        </>
    )

}

