
import Navbar from "../components/Navbar";
import Header from "../components/Header/Header";
import HomeFeatures from "../components/HomeFeatures";
import Footer from "../components/Footer/Footer";

// Images 
import BackTopBtn from "../assets/images/up-arrow.png";


export default function Home(props) {

    const { scrolltop, scrollTOP } = props;

    return (
        <>
            <Navbar scrolltop={scrolltop} />
            <Header />

            <HomeFeatures />

            <button onClick={scrollTOP} style={{ display: scrolltop > 20 ? 'block' : 'none'  }} id="myBtn">
                <img src={BackTopBtn} alt="Scroll Top" />
            </button>

            <Footer />
        </>
    )

}

