
import React from "react";

import { motion, useAnimation } from 'framer-motion';

// Ant Design
import { Progress } from 'antd';

export const ConstProgress = (props) => {

    const { percent, status, title } = props;

    return (
        <>
            <div className="row">
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                />
                <div className="col-lg-12 text-center">
                    <h2 className="h2-heading mt-5">{title}...</h2>
                    <Progress className="mt-5" type="circle" percent={percent} status={status} />
                </div>
            </div>
        </>
    );
}

export const ModalProgress = (props) => {

    const { title } = props;

    const controls = useAnimation();

    const sequence = async () => {
        await controls.start({ rotate: 360, transition: { duration: 1, ease: 'linear' } });
        controls.set({ rotate: 0 });
        sequence();
    };

    React.useEffect(() => {
        sequence();
    }, []);

    return (
        <>
            <div className="row">
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                />
                <div className="col-lg-12 text-center">
                    <h2 className="h2-heading mt-5">{title}</h2>
                    {/* <Progress percent={0} status="active" /> */}
                    <div style={{ display: "inline-block" }}>
                        <motion.div
                            //style={{ width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            animate={controls}
                            className="mt-5"
                        >
                            <motion.svg viewBox="0 0 50 50" style={{ width: 50, height: 50, color: 'black' }}>
                                <motion.circle
                                    cx="25"
                                    cy="25"
                                    r="20"
                                    fill="transparent"
                                    strokeWidth="5"
                                    stroke="currentColor"
                                    strokeDasharray="31.4 31.4"
                                    strokeLinecap="round"
                                />
                            </motion.svg>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );

}

export const ModalError = (props) => {

    const { error } = props;
    
    return (
        <>
            <div className="row">
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                />
                <div className="col-lg-12 text-center">
                    <Progress type="circle" percent="100" status="exception" />
                    <h2 className="h2-heading mt-5">Oops!</h2>
                    <div className="p-heading">
                        {error}
                    </div>
                    <button
                        type="button"
                        className="btn-outline-reg mt-5"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                </div>
            </div>
        </>
    );

}
