
import { useState } from 'react';

import { Routes, Route } from "react-router-dom";

import Home from './scenes/Home';
import AttributeAnalysis from './scenes/AttributeAnalysis/AttributeAnalysis';
import FaceDetection from './scenes/FaceDetection/FaceDetection';
import FaceVerification from './scenes/FaceVerification/FaceVerification';

import './App.css';

// Styles
import "./assets/css/styles.css";
import "./assets/css/swiper.css";
import "./assets/css/fontawesome-all.min.css";
import Contact from './scenes/Contact';

function App() {

  const [ scrolltop, setScrollTop ] = useState(0);

  window.onscroll = function () {
    setScrollTop(document.documentElement.scrollTop);
  };

  window.onload = function () {
      setScrollTop(document.documentElement.scrollTop);
  };

  const scrollTOP = () => {
    document.body.scrollTop = 0; // for Safari
    document.documentElement.scrollTop = 0; // for Chrome, Firefox, IE and Opera
  }

  return (
    <>

      <Routes>
        <Route path="/">
          <Route index element={<Home scrolltop={scrolltop} scrollTOP={scrollTOP} />} />
          <Route path="Contact" element={<Contact scrolltop={scrolltop} scrollTOP={scrollTOP} />} />
          <Route path="Features/Face/Detection" element={<FaceDetection scrolltop={scrolltop} scrollTOP={scrollTOP} />} />
          <Route path="Features/Face/Verification" element={<FaceVerification scrolltop={scrolltop} scrollTOP={scrollTOP} />} />
          <Route path="Features/Face/Analysis" element={<AttributeAnalysis scrolltop={scrolltop} scrollTOP={scrollTOP} />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>

    </>
  );
}

export default App;
