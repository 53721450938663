
import React, { useState } from "react";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer/Footer";

import axios from "axios";

// Internal Components
import { ModalError, ModalProgress, ConstProgress } from "../../components/ModalComponents";
import { AnimatedHeader } from "../AttributeAnalysis/AttributeAnalysis";

import "../../assets/css/uppy-custom.css";

// Images 
import BackTopBtn from "../../assets/images/up-arrow.png";

// Uppy
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import Webcam from '@uppy/webcam';
//import Tus from '@uppy/tus';

// Uppy Styles
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';

const Header = () => {

    return (
        <>
            <div className="basic-3 mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-container">
                                <h2 style={{ width: "fit-content" }}>Face Detection</h2>
                                <p>
                                    Our face detection tool uses state-of-the-art deep learning algorithms to accurately identify 
                                    and locate faces in any image. It can detect faces even in challenging lighting and pose conditions. 
                                    Whether you need to analyze large image datasets for research purposes, improve your photo organization 
                                    system, or enhance your app's user experience with facial recognition, our face detection tool has got you covered. 
                                    Get started today and unlock the full potential of our advanced image processing technology.
                                </p>
                                <a className="btn-solid-reg" href="#getstarted">
                                    Get Started
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

const DetectionResult = (props) => {

    const { response, imagesrc } = props;

    return (
        <>
            {/* Result AI */}
            <div className="row">
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                />
                <div className="col-lg-6 mb-3 text-center">
                    <div className="image_container">
                        <img
                            className="img-fluid"
                            src={imagesrc}
                            alt="Result Image"
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-lg-5 mt-sm-0 mt-lg-0 d-flex justify-content-center align-items-center">
                    <div>
                        <h3>Result</h3>
                        <hr />
                        <div className="mt-5 mb-5">
                            <span style={{ color : "#00c9db", fontWeight: 700, fontSize: '4.25rem', lineHeight: '2.5rem', fontFamily: " 'Montserrat', 'sans-serif' " }} >
                                {response.length === 1 ? `${response.length} Face` : `${response.length} Faces`}
                            </span>
                        </div>
                        <button
                            type="button"
                            className="btn-outline-reg"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
            {/* End AI Result */}
        </>
    );

}

const Detect = () => {

    const [ image, setImage ] = useState(null);
    const [ imageID, setImageID ] = useState(null);

    const [ ModalComponent, setModalComponent ] = useState(<ModalProgress title={"Detecting Faces"} />);

    
    const uppy = React.useMemo(() => {
        return new Uppy({
            id: 'uppy1',
            debug: true,
            autoProceed: true,
            allowMultipleUploads: false,
            restrictions: {
                maxFileSize: 50000000,
                maxNumberOfFiles: 1,
                minNumberOfFiles: 1,
                allowedFileTypes: ['image/*']
            }
        }).use(Webcam)
        //.use(Tus, { endpoint: 'https://tusd.tusdemo.net/files/' });
    }, [])

    uppy.on('complete', (result) => {
        const imageFile = result.successful[0].data;
        setImage(imageFile);
        setImageID(result.successful[0].id);
    });

    const OnDetect = (e) => {

        e.preventDefault();

        if(image !== null){

            setModalComponent(<ModalProgress title={"Detecting"} />);

            const formData = new FormData();
            formData.append('image', image);

            setImage(null);
            uppy.removeFile(imageID);
            setImageID(null);

            axios.post('https://api.zedkira.com/face/detect', formData)
            .then(response => {

                if(response.data.success){

                    setModalComponent(<ConstProgress percent={100} status={"success"} title={"Detecting"} />);
                    const base64Image = response.data.image;                   
                    //setImagesrc(`data:image/jpeg;base64,${base64Image}`);
                    setTimeout(() => {
                        setModalComponent(<DetectionResult response={response.data.faces} imagesrc={`data:image/jpeg;base64,${base64Image}`} />);
                    }, 200);
                        
                } else {
                    setModalComponent(<ModalError error={response.data.message} />);
                }

            })
            .catch(error => {
                setModalComponent(<ModalError error={error.message} />);
            });

        } else {
            setModalComponent(<ModalError error="Please Upload An Image!" />);
        }

    }

    return (
        <>

            <div id="getstarted" className="basic-3 mt-5 bg-dark-blue">
                <div className="container">
                    <AnimatedHeader />
                </div>
            </div>

            <div className="basic-5 bg-dark-blue">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-sm-12 d-flex justify-content-center align-items-center col-lg-6 mb-5">
                            <div className="image-container-uppy" style={{ width: '100%' }}>
                                <Dashboard
                                    uppy={uppy}
                                    plugins={['Webcam']}
                                    width='100%'
                                    height='650px'
                                    note={"Please upload or take a photo."}
                                    showProgressDetails={true}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-6 mb-5 text-center d-flex justify-content-center align-items-center order-first">
                            <div className="text-container">

                                <div className="mb-5">
                                    <h2 style={{ color: "black" }}>Powered By AI</h2>
                                </div>

                                <div className="mb-5">
                                    <h2>Upload or Take A Photo To Get Started</h2>
                                    <p className="mt-3">
                                        We take our users' privacy very seriously. We do not store any of the images or 
                                        the AI data that our system processes.
                                    </p>
                                    <a className="btn-solid-reg mt-3" data-bs-toggle="modal" href="#" data-bs-target="#detailsModal1" onClick={(e) => OnDetect(e)}>
                                        Detect Faces
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="details-modal">
                <div
                    id="detailsModal1"
                    className="modal fade"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {ModalComponent}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default function FaceDetection(props) {

    const { scrolltop, scrollTOP } = props;

    return (
        <>
            <Navbar scrolltop={scrolltop} />

            <Header />

            <Detect />
            
            <button onClick={scrollTOP} style={{ display: scrolltop > 20 ? 'block' : 'none'  }} id="myBtn">
                <img src={BackTopBtn} alt="Scroll Top" />
            </button>

            <Footer />
        </>
    );

}

