
import React, { useState } from "react";
import Navbar from "../../components/Navbar";

import axios from 'axios';

// Internal Components
import { ModalProgress, ConstProgress, ModalError } from "../../components/ModalComponents";

// Images

// Uppy
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import Webcam from '@uppy/webcam';
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

// CSS
import "./AttributeAnalysis.css";
import Footer from "../../components/Footer/Footer";

// Images 
import BackTopBtn from "../../assets/images/up-arrow.png";
import Face_AA from "../../assets/images/laptop_AA.png";

// Swiper Components

// Swiper Styles
import "swiper/css";
import "swiper/css/effect-cards";


import "../../assets/css/uppy-custom.css";

export const AnimatedHeader = () => {

    return (
        <div className="row">
            <div className="col-xl-10 text-center offset-xl-1">
                <h1 className="animatedHeader">Try It Now!</h1>
            </div>
        </div>
    );

}

const Description = () => {

    return (
        <div className="basic-3 mt-5 ">
            <div className="container">
                <div className="row">
                    
                    <div className="col-lg-6 col-sm-12 mt-5">
                        <div className="text-container">
                            <h2>Facial Attribute Analysis</h2>
                            <p> 
                                Our algorithms can detect a wide range of facial attributes, including age, gender, 
                                emotion (angry, fear, neutral, sad, disgust, happy and surprise), 
                                and race (including asian, white, middle eastern, indian, latino and black).
                            </p>
                            <a className="btn-solid-reg" href="#getstarted">
                                Get Started
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-6 mt-lg-5 col-sm-12 d-flex justify-content-center align-items-center">
                        <div className="image_container mt-5">
                            <img
                                className="img-fluid"
                                src={Face_AA}
                                alt="Attribute Analysis"
                            />
                            {/* <Swiper
                                effect={"cards"}
                                grabCursor={true}
                                modules={[EffectCards]}
                                className="AttributeAnalysisSwiper"
                            >
                                <SwiperSlide>
                                    <img
                                        className="img_fluid"
                                        src={Face}
                                        alt="Attribute Analysis"
                                        width="100%"
                                        height="100%"
                                    />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img
                                        className="img_fluid"
                                        src={FaceResult}
                                        alt="Attribute Analysis"
                                        width="100%"
                                        height="100%"
                                    />
                                </SwiperSlide>
                            </Swiper> */}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}

const AIResult = (props) => {

    const { response, imagesrc } = props;

    return (
        <>
            {/* Result AI */}
            <div className="row">
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                />
                <div className="col-lg-6 mb-3 text-center">
                    <div className="image_container">
                        <img
                            className="img-fluid"
                            src={imagesrc}
                            alt="Result Image"
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-lg-5 mt-sm-0 mt-lg-0 d-flex justify-content-center align-items-center">
                    <div>
                        <h3>Result</h3>
                        <hr />
                        <ul className="list-unstyled li-space-lg">
                            <li className="d-flex">
                                <i className="fas fa-chevron-right" />
                                <div className="flex-grow-1">
                                    <span style={{ color: '#00c9db' }}>Age : </span>
                                    {response['age']}
                                </div>
                            </li>
                            <li className="d-flex">
                                <i className="fas fa-chevron-right" />
                                <div className="flex-grow-1">
                                    <span style={{ color: '#00c9db' }}>Dominant Emotion : </span>
                                    {response['dominant_emotion']}
                                </div>
                            </li>
                            <li className="d-flex">
                                <i className="fas fa-chevron-right" />
                                <div className="flex-grow-1">
                                    <span style={{ color: '#00c9db' }}>Dominant Gender : </span>
                                    {response['dominant_gender']}
                                </div>
                            </li>
                            <li className="d-flex">
                                <i className="fas fa-chevron-right" />
                                <div className="flex-grow-1">
                                    <span style={{ color: '#00c9db' }}>Dominant Race : </span>
                                    {response['dominant_race']}
                                </div>
                            </li>
                        </ul>
                        {/* <a id="modalCtaBtn" className="btn-solid-reg" href="#your-link">
                            Details
                        </a> */}
                        <button
                            type="button"
                            className="btn-outline-reg"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
            {/* End AI Result */}
        </>
    );

}

const Analyze = () => {

    const [ image, setImage ] = useState(null);
    const [ imageID, setImageID ] = useState(null);
    //const [ imagesrc, setImagesrc ] = useState('');
    //const [ aiResponse, setResponse ] = useState(default_response);

    const [ ModalComponent, setModalComponent ] = useState(null);

    const uppy = React.useMemo(() => {
        return new Uppy({
            autoProceed: true,
            allowMultipleUploads: false,
            restrictions: {
                maxFileSize: 50000000,
                maxNumberOfFiles: 1,
                minNumberOfFiles: 1,
                allowedFileTypes: ['image/*']
            }
        })
          .use(Webcam) // `id` defaults to "Webcam". Note: no `target` option!
          // or
          .use(Webcam, { id: 'MyWebcam' }) // `id` is… "MyWebcam"
          /* .on("complete", result => console.log("Result From Uppy : ", result)) */
    }, [])

    uppy.on('complete', (result) => {
        const imageFile = result.successful[0].data;
        setImage(imageFile);
        setImageID(result.successful[0].id);
    });

    const OnAnalyze = (e) => {

        e.preventDefault();

        if(image !== null){

            setModalComponent(<ModalProgress title={"Analyzing"} />);

            const formData = new FormData();
            formData.append('file', image);

            const drawFormData = new FormData();
            drawFormData.append("image", image);

            setImage(null);
            uppy.removeFile(imageID);
            setImageID(null);

            axios.post('https://api.zedkira.com/face/analyze', formData)
            .then(response => {

                console.log("Full Response (Attribute Analysis : ", response)
                
                if(response.data.success){
                    console.log(response.data.result[0]);
                    
                    //setResponse(response.data[0]);
                    const AIResponse = response.data.result[0];

                    const region = response.data.result[0]['region'];
                    const { x, y, w, h } = region;

                    axios({
                        method: 'post',
                        url: `https://api.zedkira.com/draw/?x=${x}&y=${y}&width=${w}&height=${h}`,
                        data: drawFormData,
                    })
                    .then(response => {
                        //setModalComponent(<ModalProgress start={85} percent={99} interval={50} status={"normal"} />);
                        setModalComponent(<ConstProgress percent={100} status={"success"} title={"Analyzing"} />);
                        const base64Image = response.data.image;                    
                        //setImagesrc(`data:image/jpeg;base64,${base64Image}`);
                        setTimeout(() => {
                            setModalComponent(<AIResult response={AIResponse} imagesrc={`data:image/jpeg;base64,${base64Image}`} />);
                        }, 500);
                    })
                    .catch(error => {
                        setModalComponent(<ModalError error={error.message} />);
                    });
                } else {
                    setModalComponent(<ModalError error={response.data.message} />);
                }

            })
            .catch(error => {
                setModalComponent(<ModalError error={error.message} />);
            });

        } else {
            setModalComponent(<ModalError error="Please Upload An Image!" />);
        }

    }

    return (
        <>

            <div id="getstarted" className="basic-3 mt-5 bg-dark-blue">
                <div className="container">
                    <AnimatedHeader />
                </div>
            </div>

            <div className="basic-5 bg-dark-blue">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-sm-12 d-flex justify-content-center align-items-center col-lg-6 mb-5">
                            <div className="image-container-uppy" style={{ width: '100%' }}>
                                <Dashboard
                                    uppy={uppy}
                                    plugins={['Webcam', 'Dashboard']}
                                    width='100%'
                                    height='650px'
                                    note={"Please upload or take a photo."}
                                    showProgressDetails={true}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-6 text-center mb-5 d-flex justify-content-center align-items-center order-first">
                            <div className="text-container">

                                <div className="mb-5">
                                    <h2 style={{ color: "black" }}>Powered By AI</h2>
                                </div>

                                <div className="mb-5">
                                    <h2>Upload or Take A Photo To Get Started</h2>
                                    <p className="mt-3">
                                        We take our users' privacy very seriously. We do not store any of the images or 
                                        the AI data that our system processes.
                                    </p>
                                    <a className="btn-solid-reg mt-3" data-bs-toggle="modal" href="#" data-bs-target="#detailsModal1" onClick={(e) => OnAnalyze(e)}>
                                        Analyze
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="details-modal">
                <div
                    id="detailsModal1"
                    className="modal fade"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {ModalComponent}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

export default function AttributeAnalysis(props) {

    const { scrolltop, scrollTOP } = props;

    return (
        <>
            <Navbar scrolltop={scrolltop} />

            <Description />

            <Analyze />

            <button onClick={scrollTOP} style={{ display: scrolltop > 20 ? 'block' : 'none'  }} id="myBtn">
                <img src={BackTopBtn} alt="Scroll Top" />
            </button>

            <Footer />
        </>
    );

}

