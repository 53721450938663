
import "./Footer.css";

export default function Footer() {

    return (
        <>
            <div className="footer bg-dark-blue">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="footer-col first">
                                <h6>Zedkira | Deep Face</h6>
                                <p className="p-small">
                                    Our cutting-edge technology provides accurate and efficient face detection, 
                                    verification and facial attribute analysis.
                                </p>
                            </div>

                            <div className="footer-col second">
                                <h6>Links</h6>
                                <ul className="list-unstyled li-space-lg p-small">
                                    <li>
                                        <a className="footer-link" href="/Features/Face/Detection">Face Detection</a>
                                    </li>
                                    <li>
                                        <a className="footer-link" href="/Features/Face/Verification">Face Verification</a>
                                    </li>
                                    <li>
                                        <a className="footer-link" href="/Features/Face/Analysis">Facial Attribute Analysis</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="footer-col third">
                                <span className="fa-stack">
                                    <a href="https://twitter.com/Zed_kira">
                                        <i className="fas fa-circle fa-stack-2x" />
                                        <i className="fab fa-twitter fa-stack-1x" />
                                    </a>
                                </span>
                                <span className="fa-stack">
                                    <a href="https://github.com/zed-kira">
                                        <i className="fas fa-circle fa-stack-2x" />
                                        <i className="fab fa-github fa-stack-1x" />
                                    </a>
                                </span>
                                <span className="fa-stack">
                                    <a href="https://www.linkedin.com/company/zedkira/">
                                        <i className="fas fa-circle fa-stack-2x" />
                                        <i className="fab fa-linkedin fa-stack-1x"></i>
                                    </a>
                                </span>
                                <p className="p-small">
                                    For your inquiries <br></br>
                                    <a href="mailto:contact@site.com">
                                        <strong>deepface@zedkira.com</strong>
                                    </a>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            {/* Copyright */}
            <div className="copyright bg-dark-blue">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="p-small">
                                Copyright © <a href="https://www.zedkira.com/">Zedkira | Deep Face</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end of copyright */}
        </>
    );

}

