
import React, { useState, useEffect } from "react";

import { Typewriter } from 'react-simple-typewriter';

// Framer Motion
import { motion } from "framer-motion";

// Images
import try_it_now from "../../assets/images/try_it_now.png";
import attribute_analysis from "../../assets/images/home_AA.png";
import detection from "../../assets/images/detection.png";

// CSS
import "./Header.css";

const words = ["DETECTION", "VERIFICATION", "ATTRIBUTE ANALYSIS"];

const images = [
    try_it_now,
    attribute_analysis,
    detection,
];

export default function Header() {

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
          setActiveIndex((activeIndex + 1) % images.length);
        }, 4000);
    
        return () => clearInterval(intervalId);
    }, [activeIndex]);

    return (

        <>
            {/* Header */}
                <header id="header" className="header">
                    <div className="container mb-5">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="text-container">
                                    <h1 className="h1-large">
                                        Deep Face <br />
                                        {/* For Face <br /> */}
                                        <span className="replace-me">
                                            <Typewriter words={words} cursor={true} loop={0} />
                                        </span>
                                    </h1>
                                    <p className="p-large">
                                        A Lightweight online interface
                                        and Powerful API for Face Detection, Verification, and Facial Attribute Analysis.
                                    </p>
                                    <a className="btn-solid-lg" href="#homeFeatures">
                                        <i className="fab fa-critical-role" />
                                        Try It Now
                                    </a>
                                    <a className="btn-solid-lg" data-bs-toggle="modal" href="#" data-bs-target="#APIModal">
                                        <i className="fab fa-leanpub" />
                                            Docs
                                    </a>
                                </div>
                            </div>
                            
                            <div className="col-lg-6">
                                <div className="image-section">
                                    <motion.img
                                        className="img-fluid"
                                        src={images[activeIndex]}
                                        alt="Home Images"
                                        initial={{ y: -20 }}
                                        animate={{
                                            y: [20, -20],
                                            transition: {
                                                duration: 2,
                                                repeat: Infinity,
                                                repeatType: "reverse",
                                                ease: "easeInOut"
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            {/* end of header */}
        </>

    );

}

