
import React, { useState } from "react";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer/Footer";

import axios from "axios";

// Uppy
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import Webcam from '@uppy/webcam';
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

// Swiper React
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCube, Pagination } from "swiper";

// Ant Design

// Internal Components
import { AnimatedHeader } from "../AttributeAnalysis/AttributeAnalysis";
import { ModalError, ModalProgress, ConstProgress } from "../../components/ModalComponents";


// Images 
import BackTopBtn from "../../assets/images/up-arrow.png";
import VerifyLaptop from "../../assets/images/laptop-verify.png";

import "../../assets/css/uppy-custom.css";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/effect-cube";
import "swiper/css/effect-coverflow";

const Header = () => {

    return (
        <>
            <div className="basic-3 mt-5 ">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-6 col-sm-12 col-md-8 offset-lg-0 offset-md-2 mt-5">
                            <div className="text-container">
                                <h2>Securely Verify Faces !</h2>
                                <p>
                                    Our cutting-edge algorithms analyze two images and instantly determine whether 
                                    they belong to the same person or not, saving you time and resources.
                                </p>
                                <a className="btn-solid-reg" href="#getstarted">
                                    Get Started
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-6 mt-3 col-sm-12 d-flex justify-content-center align-items-center order-first">
                            <div className="image_container">
                                <img
                                    className="img-fluid mt-5"
                                    src={VerifyLaptop}
                                    alt="Face Verification"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );

}

const VerificationResult = (props) => {

    /* imagesrc={`data:image/jpeg;base64,${base64Image}`} */

    const { response } = props;

    return (
        <>
            {/* Result AI */}
            <div className="row">
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                />
                <div className="col-lg-6 mb-5 text-center">
                    <div className="image_container">
                        <Swiper
                            effect={"cube"}
                            grabCursor={true}
                            cubeEffect={{
                              shadow: true,
                              slideShadows: true,
                              shadowOffset: 20,
                              shadowScale: 0.94,
                            }}
                            pagination={true}
                            modules={[EffectCube, Pagination]}
                        >
                            <SwiperSlide>
                                <img
                                    className="img-fluid"
                                    src={`data:image/jpeg;base64,${response.first_image}`}
                                    alt="Result Image"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img
                                    className="img-fluid"
                                    src={`data:image/jpeg;base64,${response.second_image}`}
                                    alt="Result Image"
                                />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-5 mt-sm-0 mt-lg-0 d-flex justify-content-center align-items-center">
                    <div>
                        <h3>Result</h3>
                        <hr />
                        <div className="mt-5 mb-5">
                            <span style={{ color : response.verified ? "#00c9db" : "red", fontWeight: 700, fontSize: '2.25rem', lineHeight: '2.5rem', fontFamily: " 'Montserrat', 'sans-serif' " }} >
                                {response.verified ? "Same Person" : "Different People" }
                            </span>
                        </div>
                        <button
                            type="button"
                            className="btn-outline-reg"
                            data-bs-dismiss="modal"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
            {/* End AI Result */}
        </>
    );

}

const Verify = () => {

    /* const [ images, setImage ] = useState([]);
    const [ imageIDs, setImageID ] = useState([]); */

    const [ ModalComponent, setModalComponent ] = useState(<ModalProgress title={"Detecting Faces"} />);
    
    const uppy = React.useMemo(() => {
        return new Uppy({
            autoProceed: false,
            allowMultipleUploads: true,
            allowMultipleUploadBatches: false,
            debug: false,
            restrictions: {
                maxFileSize: 50000000,
                maxNumberOfFiles: 2,
                minNumberOfFiles: 2,
                allowedFileTypes: ['image/*'],
            }
        })
        .use(Webcam)
        //.on("complete", result => console.log("Result From Uppy : ", result))
    }, [])

    /* uppy.on('complete', (result) => {
        setImage([ result.successful[0].data, result.successful[1].data ]);
        setImageID([ result.successful[0].id, result.successful[1].id ]);
    }); */

    const OnVerify = (e) => {
        
        e.preventDefault();

        uppy.upload()
        .then((result) => {
            console.info('Successful uploads:', result.successful)

            if(result.successful.length === 2){
                
                setModalComponent(<ModalProgress title={"Verifying"} />);

                const formData = new FormData();
                formData.append('first_image', result.successful[0].data);
                formData.append('second_image', result.successful[1].data);

                uppy.cancelAll();

                axios.post('https://api.zedkira.com/face/verify', formData)
                .then(response => {

                    if(response.data.success){

                        setModalComponent(<ConstProgress percent={100} status={"success"} title={"Verifying"} />);
                        setTimeout(() => {
                            setModalComponent(<VerificationResult response={response.data} />);
                        }, 100);
                            
                    } else {
                        setModalComponent(<ModalError error={response.data.message} />);
                    }

                })
                .catch(error => {
                    setModalComponent(<ModalError error={error.message} />);
                });

            } else {
                setModalComponent(<ModalError error="Please Make Sure To Upload 2 Images!" />);
            }
          
        }).catch( error => {
            setModalComponent(<ModalError error={error.message} />);
        })

    }

    return (
        <>

            <div id="getstarted" className="basic-3 mt-5 bg-dark-blue">
                <div className="container">
                    <AnimatedHeader />
                </div>
            </div>

            <div className="basic-5 bg-dark-blue">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-sm-12 col-lg-6 mb-5"> {/* d-flex justify-content-center align-items-center */}
                            <div className="image-container-uppy" style={{ width: '100%' }}>
                                <Dashboard
                                    uppy={uppy}
                                    plugins={['Webcam', 'Dashboard']}
                                    width='100%'
                                    height='650px'
                                    note={"Please upload or take a photo."}
                                    showProgressDetails={true}
                                    hideUploadButton={true}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-6 text-center mb-5 d-flex justify-content-center align-items-center order-first">

                            <div className="text-container">

                                <div className="mb-5">
                                    <h2 style={{ color: "black" }}>Powered By AI</h2>
                                </div>

                                <div className="mb-5">
                                    <h2>Upload Two Faces To Get Started</h2>
                                    <p className="mt-3">
                                        We take our users' privacy very seriously. We do not store any of the images or 
                                        the AI data that our system processes.
                                    </p>
                                    <a className="btn-solid-reg mt-3" data-bs-toggle="modal" href="#" data-bs-target="#detailsModal1" onClick={(e) => OnVerify(e)}>
                                        Verify
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="details-modal">
                <div
                    id="detailsModal1"
                    className="modal fade"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {ModalComponent}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

export default function FaceVerification(props) {

    const { scrolltop, scrollTOP } = props;

    return (
        <>
            <Navbar scrolltop={scrolltop} />

            <Header />

            <Verify />
            
            <button onClick={scrollTOP} style={{ display: scrolltop > 20 ? 'block' : 'none'  }} id="myBtn">
                <img src={BackTopBtn} alt="Scroll Top" />
            </button>

            <Footer />
        </>
    );

}

