
import { useState } from 'react';

// Images
import logo from "../assets/images/logo.png";
import beta from "../assets/images/beta_icon.png";



export default function Navbar(props) {

    const { scrolltop } = props;

    const [ toggleOpen, setToggleOpen ] = useState(false);

    const OnToggleClick = () => {
        setToggleOpen(!toggleOpen);
    }

    return (
        <>
            <nav id="navbarExample" className={ scrolltop > 30 ? "navbar navbar-expand-lg fixed-top navbar-dark top-nav-collapse" : "navbar navbar-expand-lg fixed-top navbar-dark" } aria-label="Main navigation">
                <div className="container">

                    {/* Image Logo  */}
                    <div className="navbar-brand logo-image d-flex justify-content-center align-items-center">
                        <a className="" href="https://www.zedkira.com/">
                            <img src={logo} style={{ width: "50px", height: "50px"}} alt="alternative" />
                        </a>
                        <span 
                            style={{ 
                                marginLeft: "10px",
                                textAlign: "center", 
                                /* color: "#00c9db", */
                                fontFamily: '"Montserrat", sans-serif',
                            }}
                        >
                            Deep Face
                        </span>
                        <span className="nav-item social-icons" style={{ marginLeft: "5px", display: 'flex', justifyContent: 'center' }}>
                            <img src={beta} style={{ width: "50px", height: "50px"}} alt="Beta" />
                        </span>
                    </div>

                    <button onClick={OnToggleClick} className="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={ toggleOpen ? "navbar-collapse offcanvas-collapse open" : "navbar-collapse offcanvas-collapse" } id="navbarsExampleDefault">
                        <ul className="navbar-nav ms-auto navbar-nav-scroll">
                            <li className="nav-item">
                                <a className="nav-link" href="/">Home</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Features</a>
                                <ul className="dropdown-menu" aria-labelledby="dropdown01">
                                    <li><a className="dropdown-item" href="/Features/Face/Detection">Face Detection</a></li>
                                    <li><div className="dropdown-divider"></div></li>
                                    <li><a className="dropdown-item" href="/Features/Face/Verification">Face Verification</a></li>
                                    <li><div className="dropdown-divider"></div></li>
                                    <li><a className="dropdown-item" href="/Features/Face/Analysis">Facial Attribute Analysis</a></li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="modal" href="#" data-bs-target="#pricingModal">Pricing</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="modal" href="#" data-bs-target="#APIModal">API</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/Contact">Contact</a>
                            </li>
                        </ul>
                        <span className="nav-item social-icons" style={{ display: 'flex', justifyContent: 'center' }}>
                            <span className="fa-stack">
                                <a href="https://www.linkedin.com/company/zedkira/">
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fab fa-linkedin-f fa-stack-1x"></i>
                                    <i className="fab fa-linkedin fa-stack-1x"></i>
                                </a>
                            </span>
                            <span className="fa-stack">
                                <a href="https://twitter.com/Zed_kira">
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fab fa-twitter fa-stack-1x"></i>
                                </a>
                            </span>
                        </span>
                    </div> {/* end of navbar-collapse */}
                </div> {/* end of container */}
            </nav>

            {/* Pricing Modal */}
            <div className="details-modal">
                <div
                    id="pricingModal"
                    className="modal fade"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="row">
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                                <div className="col">
                                    <h3>Beta</h3>
                                    <hr />
                                    <ul className="list-unstyled li-space-lg">
                                        <li className="d-flex">
                                            <i className="fas fa-chevron-right" />
                                            <div className="flex-grow-1">
                                                <p>
                                                    Try out DeepFace, the latest face recognition technology! 
                                                    Our online interface is now available for free during our beta launch. 
                                                    Test out our cutting-edge features to detect and verify faces with high accuracy. 
                                                    Please note that we currently only offer an online interface, and a pro version with pricing will be available soon. 
                                                    In addition, a public API will be released in the near future. Join us now and stay tuned for updates! 
                                                </p>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <i className="fas fa-chevron-right" />
                                            <div className="flex-grow-1">
                                                We highly value your feedback and would appreciate any comments or suggestions you may 
                                                have to help us improve our product. Please send your feedback to 
                                                <a href='mailto:deepface@zedkira.com'> deepface@zedkira.com</a>. 
                                                Thank you for your support!
                                            </div>
                                        </li>
                                    </ul>
                                    <button
                                        type="button"
                                        className="btn-outline-reg"
                                        data-bs-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* END : Pricing Modal */}

            {/* Pricing Modal */}
            <div className="details-modal">
                <div
                    id="APIModal"
                    className="modal fade"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="row">
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                                <div className="col">
                                    <h3>Beta</h3>
                                    <hr />
                                    <ul className="list-unstyled li-space-lg">
                                        <li className="d-flex">
                                            <i className="fas fa-chevron-right" />
                                            <div className="flex-grow-1">
                                                <p>
                                                    Deep Face is in Beta! 
                                                    Please note that we currently only offer a FREE online interface, 
                                                    a public API will be released in the near future. Join us now and stay tuned for updates! 
                                                </p>
                                            </div>
                                        </li>
                                        <li className="d-flex">
                                            <i className="fas fa-chevron-right" />
                                            <div className="flex-grow-1">
                                                We highly value your feedback and would appreciate any comments or suggestions you may 
                                                have to help us improve our product. Please send your feedback to 
                                                <a href='mailto:deepface@zedkira.com'> deepface@zedkira.com</a>. 
                                                Thank you for your support!
                                            </div>
                                        </li>
                                    </ul>
                                    <button
                                        type="button"
                                        className="btn-outline-reg"
                                        data-bs-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* END : Pricing Modal */}
        </>
    );

}
