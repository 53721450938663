
import FaceDetectionResp from "../assets/images/desktop_face_detection.png";
import DetectionLaptop from "../assets/images/laptop-try-it-now.png";
import AttAnalMobile from "../assets/images/attribute_analysis.png";


export default function HomeFeatures() {

    return (
        <>
            {/* Features */}
                <div className="tabs-1">
                    <div className="container">

                        {/* Header : Features */}
                        <div id="homeFeatures" className="row">
                            <div className="col-lg-12">
                                <h2 className="h2-heading">FEATURES</h2>
                                <div className="p-heading"> 
                                    Our feature-rich platform provides unparalleled accuracy and speed, allowing you to easily detect, 
                                    verify, and analyze facial attributes. Discover the potential of Deep Face and elevate 
                                    your project to the next level.
                                </div>
                            </div>
                        </div>
                        {/* End Header : Features */}


                        <div className="row">

                            {/* Tabs Links */}
                            <ul className="nav nav-tabs" id="navTabs" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        id="nav-tab-1"
                                        data-bs-toggle="tab"
                                        data-bs-target="#tab-3"
                                        role="tab"
                                        aria-controls="tab-1"
                                        aria-selected="true"
                                    >
                                        <i className="fas fa-cog" />
                                        Facial Attribute Analysis
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="nav-tab-2"
                                        data-bs-toggle="tab"
                                        data-bs-target="#FaceDetectionTab"
                                        role="tab"
                                        aria-controls="tab-2"
                                        aria-selected="false"
                                    >
                                        <i className="fas fa-binoculars" />
                                        Face Detection
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="nav-tab-3"
                                        data-bs-toggle="tab"
                                        data-bs-target="#FaceVerificationTab"
                                        role="tab"
                                        aria-controls="tab-3"
                                        aria-selected="false"
                                    >
                                        <i className="fas fa-search" />
                                        Face Verification
                                    </a>
                                </li>
                            </ul>
                            {/* end of tabs links */}


                            {/* Tabs Content */}
                            <div className="tab-content" id="navTabsContent">

                                {/* Tab : Face Detection */}
                                <div className="tab-pane fade" id="FaceDetectionTab" role="tabpanel" aria-labelledby="nav-tab-2">
                                    <div className="basic-3 bg-dark-blue">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-5 mt-lg-5 text-center">
                                                    <div className="image_container">
                                                        <img
                                                            className="img-fluid"
                                                            src={FaceDetectionResp}
                                                            style={{
                                                                Width: "100%",
                                                                Height: "100%",
                                                            }}
                                                            alt="Face Detection Image"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 text-center mt-sm-5 mt-lg-0 col-lg-7">
                                                    <div className="mt-5">
                                                        <h2 style={{ width: 'fit-content' }}>Discover the Power of Face Detection</h2>
                                                        <p>
                                                            Experience the latest in image processing technology with our face detection feature. 
                                                            Whether it's for security, personalization, or just for fun, our website's face detection can identify 
                                                            faces in images with incredible accuracy. Try it out today and see the results for yourself!
                                                        </p>
                                                        <a className="btn-solid-reg" href="/Features/Face/Detection">
                                                            Try This Feature
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Tab : Face Detection */}

                                {/* Tab : Face Verification */}
                                <div className="tab-pane fade" id="FaceVerificationTab" role="tabpanel" aria-labelledby="nav-tab-1">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-12 col-lg-5 text-center offset-xxl-1 offset-md-1 mt-5 mb-5">
                                                <div className="text-container">
                                                    <h2>Face Verification</h2>
                                                    <p className="mt-3">
                                                        Verify the identity of your users with ease using our Face Verification feature. 
                                                        Our advanced algorithms compare the faces of two images and determine whether they 
                                                        are of the same person or not with high accuracy and speed.
                                                    </p>
                                                    <a className="btn-solid-reg mt-3" href="/Features/Face/Verification">
                                                        Try This Feature
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-lg-5 mt-sm-5 mt-md-5 mt-lg-3 text-center">
                                                <div className="image_container mt-5">
                                                    <img
                                                        className="img-fluid"
                                                        src={DetectionLaptop}
                                                        alt="Attribute Analysis"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* Tab : Facial Attribute Analysis */}
                                <div className="tab-pane fade show active" id="tab-3" role="tabpanel" aria-labelledby="nav-tab-3">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-5 offset-xxl-1 offset-md-1 text-sm-center mt-lg-5 ">
                                                <div className="row">

                                                    <div className="row">
                                                        <div className="col-lg-12 col-sm-12 col-md-12 text-center">
                                                            <h3>Facial Attribute Analysis</h3>
                                                            <p className="sub-heading">
                                                                Get accurate information about facial attributes, such as age, gender, emotion, and facial 
                                                                expressions, with our Facial Attribute Analysis feature.
                                                            </p>
                                                            <a className="btn-try mb-5 mt-lg-5" href="/Features/Face/Analysis#getstarted">
                                                                Try This Feature
                                                            </a>
                                                        </div>
                                                        {/* <div className="col-sm-12 col-md-6 mt-sm-2 d-flex justify-content-center align-items-center">
                                                            <a className="btn-try" href="/Features/Face/Analysis">
                                                                Try This Feature
                                                            </a>
                                                        </div> */}
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-4 col-lg-6 mt-md-5 mt-lg-0 offset-lg-0 offset-md-2">
                                                            <ul className="list-unstyled li-space-lg first mt-lg-5">
                                                                <li className="d-flex">
                                                                    <div className="icon">
                                                                        <span className="fa-stack">
                                                                            <i className="fas fa-circle fa-stack-2x" />
                                                                            <i className="fas fa-user-check fa-stack-1x" />
                                                                            {/* <i className="fas fa-cube fa-stack-1x" /> */}
                                                                        </span>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <h5>Age</h5>
                                                                    </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon">
                                                                        <span className="fa-stack">
                                                                            <i className="fas fa-circle fa-stack-2x" />
                                                                            <i className="fas fa-users fa-stack-1x" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <h5>Race</h5>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-sm-12 mt-md-5 col-md-6">
                                                            <ul className="list-unstyled li-space-lg mt-lg-0">
                                                                <li className="d-flex">
                                                                <div className="icon">
                                                                    <span className="fa-stack">
                                                                    <i className="fas fa-circle fa-stack-2x" />
                                                                    <i className="fas fa-smile-beam fa-stack-1x" />
                                                                    </span>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <h5>Emotion</h5>
                                                                </div>
                                                                </li>
                                                                <li className="d-flex">
                                                                    <div className="icon">
                                                                        <span className="fa-stack">
                                                                            <i className="fas fa-circle fa-stack-2x" />
                                                                            <i className="fas fa-venus-mars fa-stack-1x" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <h5>Gender</h5>
                                                                    </div>
                                                                </li>
                                                            </ul>  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <img
                                                    className="img-fluid"
                                                    src={AttAnalMobile}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%"
                                                    }}
                                                    alt="alternative"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* end of tab-content */}

                        </div>


                    </div>
                </div>
            {/* end of features */}
        </>
    );

}

